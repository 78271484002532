// FooterMenu.jsx
import React from 'react';

const FooterMenu = () => {
  return (
    <div className="top-footer">
      <div className="row">
        <div className="col-3">
          <a href="/contact" className="mat-button">
            <span className="mat-button-wrapper">Contact</span>
          </a>
        </div>
        <div className="col-3">
          <a href="https://regulardrive.net/legal/tos.html" className="mat-button">
            <span className="mat-button-wrapper">Terms of Service</span>
          </a>
        </div>
        <div className="col-3">
          <a href="https://regulardrive.net/legal/privacy.html" className="mat-button">
            <span className="mat-button-wrapper">Privacy Policy</span>
          </a>
        </div>
      </div>
      <div className="footer-icons">
          {/* Social Media Icons */}
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook"></i>
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-youtube"></i>
          </a>

          {/* Add more social media icons as needed */}
        </div>
    </div>
  );
};

export default FooterMenu;
